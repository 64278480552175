import React from "react"

const FooterCR = () => (
  <p className="cr">
    Wykonano w <a href="https://www.gatsbyjs.org">GatsbyJS</a>. Projekt i
    realizacja: <a href="http://dygresje.info">dygresje.info</a>; 2020.
  </p>
)

export default FooterCR
